import React from "react";
import {
    ChipField,
    DateField,
    DateInput,
    Filter,
    FunctionField,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput
} from 'react-admin';

import copy from 'copy-to-clipboard';

import {ListGuesser} from "@api-platform/admin";
import Title from "../../Layout/title";


const validateType = [required('Required')];

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);


const WidgetFilter = (props) => (
    <div>
        <Filter {...props}>


            <ReferenceInput label="Owner" source="owner" reference="users"
                            allowEmpty={true}
                            validate={validateType}
                            allowNull
            >
                <SelectInput optionText="username"/>
            </ReferenceInput>
            <DateInput source="validfrom[after]"/>
            <DateInput source="validfrom[strictly_before]"/>
        </Filter>

    </div>
);


const truncate = ({


    truncateHash: function (rawRecord) {
        //Round to MW(h)

        let value = rawRecord.hash;
        let str = rawRecord.hash;
        return value ? (
            <div className="MuiChip-root" onClick={() => {
                console.log(value);
                copy(value);
                alert("Key copied to your clipboard");

            }
            }>
            <span className="MuiChip-label">
            <span>{str.length > 20 ? str.slice(0, 20) + "..." : str}</span>
                            </span>
            </div>
        ) : null;
    }
});


const WidgetList = (props) => (
<>
<Title pageTitle="Widget Key List" />
    <ListGuesser  {...props} filters={<WidgetFilter/>} hasShow={false}>


        <FunctionField label="Key" render={utilization => truncate.truncateHash(utilization)}/>


        <ReferenceField label="Owner" source={"owner"} reference="users" link="show">
            <ChipField source="username"/>
        </ReferenceField>
        <DateField source={"validfrom"} showTime/>
        <DateField source={"validto"} showTime/>


    </ListGuesser>
    </>
);

export default WidgetList;