// in src/Dashboard.js
import * as React from "react";
import { Card, CardContent } from '@material-ui/core';
import IframeResizer from 'iframe-resizer-react'
import dataProvider from '../providers/dataProvider';
import Title from "../Layout/title";



function updateFrame(msg) {


    let polls = document.querySelectorAll('[id ^= "iFrameResizer"]');
    Array.prototype.forEach.call(polls, callback);


    function callback(element, iterator) {
        console.log(iterator, element.id);

        let frame = element

        console.log('msg', msg);
        if(frame) {
            if(msg) {
                frame.contentWindow.postMessage(msg, '*');
            }
        }

    }



}


window.addEventListener("message", (event) => {

    if (event.origin !== "https://dashboard.netanders.io" && event.origin !== "https://netandersdashboard.dev.dycon.nl")
        return;
    if(event.data === 'SendMeKeys') {
        getWidgetKeys();
    }

    // ...
}, false);


function getWidgetKeys() {

    // dataProvider
    //     .getOne('widgets', { id: '/v1/widgets/AF4EF1182329289249E5C20727ED9271C8235B08A2F62CAF23F18378ACCEF863' })
    //     .then(response => {
    //         console.log(response.data); // { id: 123, title: "hello, world" }
    //     });

    dataProvider
        .getList('widgets', {
            pagination: { page: 1, perPage: 10 },
            sort: {  },
            filter: { },
        })
        .then(response => {
            console.log(response.data); // { id: 123, title: "hello, world" }
            updateFrame(response.data);


        });



}








export default () => (



<>
    <Title pageTitle="Widget wizzard" />
    <Card>
        <CardContent>


            <IframeResizer
                heightCalculationMethod="bodyScroll"

                src="/Widgets/chartWizzard.html"
                style={{ width: '1px', minWidth: '100%', height:'5000px', border: 'none'}}
            />

        </CardContent>
    </Card>
    </>
);


