import * as React from 'react';
import { cloneElement } from 'react';
import {
    useListContext,
    TopToolbar,



    sanitizeListRestProps,
} from 'react-admin';

import CustomizedDialogs from './modal';

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            {/*<CreateButton basePath={basePath} />*/}
            {/*<ExportButton*/}
            {/*    disabled={total === 0}*/}
            {/*    resource={resource}*/}
            {/*    sort={currentSort}*/}
            {/*    filterValues={filterValues}*/}
            {/*    maxResults={maxResults}*/}
            {/*/>*/}
            {/* Add your custom actions */}
            <CustomizedDialogs
                disabled={total === 0 || total >= 64000}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
                total={total}
            >
            </CustomizedDialogs>
        </TopToolbar>
    );
};

export default ListActions;