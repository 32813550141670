import React, { useState } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { TextField, Button, Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
}));

const LoginForm = ({ theme }) => {
    const [apikey, setApiKey] = useState('');
    const login = useLogin();
    const notify = useNotify();
    const classes = useStyles();

    const submit = (e) => {
        e.preventDefault();
        login({ username: apikey })
            .catch(() => notify('Invalid API Key', 'warning'));
    };

    return (
        <ThemeProvider theme={createMuiTheme(theme)}>
            <Paper className={classes.paper}>
                <form onSubmit={submit}>
                    <TextField
                        id="apikey"
                        name="apikey"
                        label="API Key"
                        value={apikey}
                        onChange={e => setApiKey(e.target.value)}
                        margin="normal"
                        required
                        fullWidth
                    />
                    <Button type="submit" color="primary" variant="contained" fullWidth>
                        Sign in
                    </Button>
                </form>
                <Notification />
            </Paper>
        </ThemeProvider>
    );
};

export default LoginForm;