import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { fetchEnd, fetchStart } from 'react-admin';
import LinearProgressWithLabel from './progressbar';
import Fade from '@material-ui/core/Fade';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';

import GetApp from '@material-ui/icons/GetApp';

var timeOutKillSwitch = true;

const cdnentrypoint = process.env.REACT_APP_CDN;

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {


    const [modalOpen, setModalOpen] = React.useState(false);
    const [progress, setProgress] = React.useState({ percentage:0, remaining: -1 });
    const [inProgress, setInProgress] = React.useState(true);
    const [isFinished, setIsFinished] = React.useState(false);
    const [isFailed, setFailed] = React.useState(false);




    const handleClickOpen = () => {
        setModalOpen(true);

        setIsFinished(false);
        timeOutKillSwitch = false;
        buildDownloadUrl(props);
        fireDownload(props);
    };
    const handleClose = () => {
        setModalOpen(false);
        setFailed(false);
        fetchEnd();
        timeOutKillSwitch = true;
    };





    const buildDownloadUrl = (props) => {

        function convertParams(obj) {
            var str = "";
            for (var key in obj) {

                // Validfrom en validto dates are objects
                if(typeof obj[key] === 'object') {
                    let inObj = obj[key];
                    for(var inkey in inObj) {
                        if (str !== "") {
                            str += "&";
                        }
                        str += key + "["+inkey+"]=" + encodeURIComponent(inObj[inkey]);
                    }
                    // prevent the object from beeing inserted aswel
                    continue;
                }
                if (str !== "") {
                    str += "&";
                }
                str += key + "=" + encodeURIComponent(obj[key]);
            }
            return str;
        }




        let entry = '/v1/';
        let resource = props.resource;
        let filtervalues = convertParams(props.filterValues);
        let defaultParams = '&page=1&itemsPerPage=144';

        let sortParams = "";
        if(props.sort.field !== 'validfrom') {
            sortParams = '&' + encodeURIComponent('order[' + props.sort.field + ']=' + props.sort.order);
        }


        let url = entry + resource + '?' + filtervalues + defaultParams + sortParams

        // console.log(url);
        // console.log(decodeURIComponent(url));

        return url;

    }

    const getDownloadKey = () => {

        return localStorage.getItem('token');

    }

    const getPostData = (props) => {

        let obj = {
            url: buildDownloadUrl(props),
            key: getDownloadKey()
        }

        return obj;
    }

    const fireDownload = (props) => {

        fetchStart();


        fetch(cdnentrypoint + '/downloadCsv', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(getPostData(props))

        }).then(
            response => {

                if(response.ok) {

                    return response.json();
                }
            }
        ).then(data => {
            fetchEnd();
            statusPoller(cdnentrypoint + data["@exportid"]);
        })

    }

    const updateText = (data) => {
        setProgress(data);
        if(data.percentage >= 1) {
            setInProgress(false);
        }
        else if (data.percentage < 1 && progress !== true) {
            setInProgress(true);
        }
        if(data.message === "Finished" && data.download.length !== 0) {
            setIsFinished(true);
        }
    }



    const statusPoller = (id) => {
        window.apiTimeout = setTimeout(fetchAPIData, 1000, id);


        function fetchAPIData(id){
            fetch(id)
                .then(res => {

                    if(res.status === 200){
                        // Process the response and update the view.

                        res.json().then(data => {

                            updateText(data);

                            if(data.status === 'failed') {

                                setFailed(true);

                            }
                            else if(data.percentage < 1) {

                                // Recreate a setTimeout API call which will be fired after 1 second.
                                if(timeOutKillSwitch === false) {
                                    window.apiTimeout = setTimeout(fetchAPIData, 1000, id);
                                }
                            }





                            else {
                                clearTimeout(window.apiTimeout);
                            }



                        }).catch(e => {
                                clearTimeout(window.apiTimeout);
                            }

                        )



                    }else{
                        clearTimeout(window.apiTimeout);
                        // Failure case. If required, alert the user.
                    }
                });
        }


    }


    const secondsToString = (seconds) => {
        function numberEnding (number) {
            return (number > 1) ? 's' : '';
        }

        if(seconds < 0) {
            return "initializing"
        }

        var years = Math.floor(seconds / 31536000);
        if (years) {
            return years + ' year' + numberEnding(years);
        }
        //TODO: Months! Maybe weeks?
        var days = Math.floor((seconds %= 31536000) / 86400);
        if (days) {
            return days + ' day' + numberEnding(days);
        }
        var hours = Math.floor((seconds %= 86400) / 3600);
        if (hours) {
            return hours + ' hour' + numberEnding(hours);
        }
        var minutes = Math.floor((seconds %= 3600) / 60);
        if (minutes) {
            return minutes + ' minute' + numberEnding(minutes);
        }
        seconds = seconds % 60;
        if (seconds) {
            return seconds + ' second' + numberEnding(seconds);
        }
        return 'allmost ready'; //'just now' //or other string you like;

    }


    /**
     * Format bytes as human-readable text.
     *
     * @param bytes Number of bytes.
     * @param si True to use metric (SI) units, aka powers of 1000. False to use
     *           binary (IEC), aka powers of 1024.
     * @param dp Number of decimal places to display.
     *
     * @return Formatted string.
     */
    const humanFileSize = (bytes, si=true, dp=1) => {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10**dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


        return bytes.toFixed(dp) + ' ' + units[u];
    }


    // const showElapsedTime = (starttime) => {
    //
    // let now = Math.floor(Date.now() / 1000);
    // let elapsed = now - starttime;
    // return secondsToString(elapsed);
    //
    // }


    const getExportTooltip = (props) => {
        if(props.total === null || props.total === 0 ) {
            return "Your selection filters doesnt yield any data to export";
        }
        if(props.total >= 64000) {
            return "Your selection yields more than 64000 rows, please make your selection more specific";
        }
        else {
            return "Click on this button to start the export";
        }

    }

    const exportDisabled = (props) => {
        if(props.total === null || props.total === 0 ) {
            return true;
        }
        if(props.total >= 65000) {
            return true
        }
        else {
            return false
        }
    }

    const getFileName = (props) => {
        return "download.csv";
    }




    return (
        <div {...props}>

            <Tooltip title={getExportTooltip(props)}>
                <span>
            <Button disabled={exportDisabled(props)}  variant="primary" size="sm" onClick={handleClickOpen} label="export">
                <GetApp />
                <span>Export</span>

            </Button>
                </span>
            </Tooltip>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={modalOpen}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    CSV Export
                </DialogTitle>
                <DialogContent dividers>
                    <Collapse in={inProgress} >
                    <Fade in={inProgress}>
                    <Typography gutterBottom>


                        <LinearProgressWithLabel value={Math.round(progress.percentage * 100)} />
                        <Typography gutterBottom>
                            Estimated time to finish: {secondsToString(progress.remaining)} </Typography>

                        <Typography gutterTop>
                            Please be patient and wait for your export to finish.

                            Please do not re-export if the process seems to be taking a long time. This only adds to the queue and causes a longer export time for everyone.
                        </Typography>

                    </Typography>


                    </Fade>
                    </Collapse>
                    <Collapse in={isFinished} >
                    <Fade in={isFinished}>
                    <Typography gutterBottom>
                        Your export is ready for download <br />

                        <a href={ cdnentrypoint + progress.download} target="_blank" rel="noopener noreferrer" download={getFileName(props)}>
                            <Button autoFocus variant="success" size="lg" onClick={handleClose}>
                                <GetApp />
                                <span>Download your export ({humanFileSize(progress.download_size)})</span>
                            </Button>
                        </a>


                    </Typography>
                    </Fade>
                    </Collapse>

                    <Collapse in={isFailed} >
                        <Fade in={isFailed}>
                    <Typography color="error" >
                        <p>The export has failed, please try again.</p>
                        <p><i>Error: {progress.message}</i></p>
                    </Typography>
                        </Fade></Collapse>

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        Sluit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
