// in src/comments/ApproveButton.js
import React, { useState } from 'react';
import { useNotify, useRedirect, Button, Loading } from 'react-admin';
import dataProvider from '../../providers/dataProvider';

const ApproveButton = ({ record }) => {

    const redirect = useRedirect();
    const notify = useNotify();
    const [user] = useState();
    const [loading, setLoading] = useState(false);
    const handleClick = () => {
            dataProvider.update('widgets', {
                id: record.id + '/renew',
                data: { },
                previousData: { hash: record.hash } })
                .then(({ data }) => {
                    console.log(data);
                    setLoading(false);
                    notify('New Key Generated');
                    redirect('/widgets/%2Fv1%2Fwidgets%2F'+data.hash);
                })
                .catch(error => {

                    console.log(error);
                    notify('Failed to generate a new key', 'error');
                    setLoading(false);
                })


        if (loading) return <Loading />;
        if (!user) return null;
    };
    return <Button label="Generate new key" onClick={handleClick} disabled={loading} />;
};

export default ApproveButton;