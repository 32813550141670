// in src/Dashboard.js
import * as React from "react";
import { Card, CardContent, CardHeader } from '@material-ui/core';
import Title from "../Layout/title";


let documentation = '<p>Before you start  using the&nbsp;<strong>NetAnders Widgets</strong>, you need a&nbsp;<strong>NetAnders API</strong>&nbsp;account  and a <strong>Widget key</strong> enabled. Request your Accountmanager to obtain an account or  a new Widget key. <br />\n' +
    '  <strong>What are Widget  keys?<br />\n' +
    '  </strong>Widget keys are project-centric credentials  that serve two purposes: </p>\n' +
    '<ul type="disc">\n' +
    '  <li><strong>Project Identification</strong><br />\n' +
    '    Identify the app or the project that\'s making a call to the API. </li>\n' +
    '  <li><strong>Project Authorization</strong><br />\n' +
    '    Check whether the calling app has been granted access. </li>\n' +
    '</ul>\n' +
    '<p>The Widget key is  an unique identifier that authenticates requests associated with your widget  for usage and billing purposes. You must have at least one Widget key  associated with your project. <br />\n' +
    '  In the NetAnders Dashboard you can find the menu &lsquo;Widgets&rsquo;.  There are 4 submenus within the Widget menu. Below we will describe what you  can do within these different menus.</p>\n' +
    '<h2>1. Widget Demo</h2>\n' +
    '<p>In this menu different types of graphs and possible views  are demonstrated. All of these examples can be used on the website/page of your  preference.</p>\n' +
    '<h2>2. Widget Wizzard</h2>\n' +
    '<h3>2.1. Chart</h3>\n' +
    '<p>On this page you can easely generate the embed code for the Chart widget to place on your project. You can select your prefered set-up and see the changes in the  preview. Furthermore you can find the  specific iframe embed code to use in your website, or simply click on the copy code button once you\'re done.</p>\n' +
    '<h4>2.1.1. Widget ID</h4>\n' +
    '<p>Provide the  Widget ID key for which you would like to create a Wizzard. It is recommended  that you use a specific key just once, as you, in case of unauthorized usage, need to  replace the old key by the new key.</p>\n' +
    '<h4>2.1.2. Scale</h4>\n' +
    '<p>Select the  desired scale of the data. You are able to choose be per day, per month or per  year. </p>\n' +
    '<h4>2.1.3. Date</h4>\n' +
    '<p>Select the date  you want to display, format: yyyy-mm-dd. Leave these fields empty to display  the current Day / Month /Year. This will depend on the scale you have seleted  (see 2.1.2). </p>\n' +
    '<h4>2.1.4. Sources</h4>\n' +
    '<p>Select the  sources you want to display. It is possible to select multiple options. </p>\n' +
    '<h4>2.1.5. Theme</h4>\n' +
    '<p>You can choose for a light or dark background  or use the default settings. Please check the menu ´Widget Demo´ for an  example.</p>\n' +
    '<h4>2.1.6. Tooltip</h4>\n' +
    '<p>There is a  possibility to enable the tooltip. This will allow a user to interact with the  chart and discover the data in detail. </p>\n' +
    '<h4>2.1.7. Stacked</h4>\n' +
    '<p>There is a  possibility to enable the Stack Mode. Stack the source values instead of  overlapping. </p>\n' +
    '<h4>2.1.8. Background</h4>\n' +
    '<p>Set the  background of the chart to transparent to use a custom background.<br />\n' +
    '  Once you have set all your preferences, you can copy the code to your clipboard  by clicking the button. </p>\n' +
    '<h3>2.2. Counter</h3>\n' +
    '<p>On this page you can easely generate the embed code for the Counter widget to place on your project. You can select your prefered set-up and see the changes in the  preview. Furthermore you can find the  specific iframe embed code to use in your website, or simply click on the copy code button once you\'re done.</p>\n' +
    '<h4>2.2.1. Widget ID</h4>\n' +
    '<p>Provide the Widget ID key for which  you would like to create a counter. </p>\n' +
    '<h4>2.2.2. Type</h4>\n' +
    '<p>Please select if you want to display a MWh counter or a Household counter.</p>\n' +
    '<h4>2.2.3. Theme</h4>\n' +
    '<p>You can choose for a light or dark background or use the default settings.  Please check the menu ´Widget Demo´ for an example.</p>\n' +
    '<h4>2.2.4. Background</h4>\n' +
    '<p>Set the background of the counter to  transparent to use a custom background. <br />\n' +
    '  Once you have set all your  preferences, you can copy the code to your clipboard by clicking the button. </p>\n' +
    '\n' +
    '<h2>3. Widget Key Management</h2>\n' +
    '<p>On this page you are able to manage your Widget Keys. Widget keys are  credentials, and you should manage them carefully. At a minimum, follow the  recommendations below to keep your keys safe, and to make sure that you have  restrictions in place to reduce the impact of compromised Widget keys. <br />\n' +
    '  You can restrict  a Widget key by specifying an Hostname/Domain or IP address or IP range </p>\n' +
    '<p>Restricting  Widget keys adds security to your application by ensuring only authorized  requests are made with your Widget key. We strongly recommend that you follow  the instructions to set restrictions for your Widget keys. <br />\n' +
    'To restrict a  Widget key please note the following steps: </p>\n' +
    '<ul>\n' +
    '  <li>In your Widget Key mangement menu you can find an  overview of your activated Widget Keys. When you click &lsquo;Edit&rsquo;, a new page will  open. </li>\n' +
    '\n' +
    '  <li>You can manage the access control type and restrict  your Widget keys.&nbsp;You can best protect your Widget key by restricting it  to specific  referrer URLs as this significantly  reduces the impact of a key compromise. There are 3 options: </li>\n' +
    '  <ul>\n' +
    '    <li>No access control (default). There is no access  control. </li>\n' +
    '    <li>Host/domainname based access control (recommended).  \n' +
    '      <ul>\n' +
    '        <li>Please fill in one or more valid hostnames or domains without http:// https://        </li>\n' +
    '        <li>Domains do not have to be publically accessible (localhost or *.intranet.lan for example is valid)<br />\n' +
    '        </li>\n' +
    '      </ul>\n' +
    '    </li>\n' +
    '    <li>IP based access control. This can be a IPv4, IPv6 or  CIDR notation. \n' +
    '      <ul>\n' +
    '        <li>IP address must be hosting the widget publically (127.0.0.1 or 192.168.1.10 for example are not valid)</li>\n' +
    '      </ul>\n' +
    '    </li>\n' +
    '  </ul>\n' +
    '    <li>If you observe unauthorized usage, you are able to  regenerate a new key. It is recommended to use one key per Widget Wizzard as  you need to replace the old key by the new key. </li>\n' +
    '</ul>\n' +
    '\n' +
    '<h2>4. Widget documentation</h2>\n' +
    '<p>Within this menu you can find relevant information regarding  the use of your Widget Key(s).</p>\n';





export default () => (



<>
    <Title pageTitle = "Widget Documentation" />
    <Card>
        <CardHeader title="NetAnders Widget Documentation" />
        <CardContent>

            {/*<IframeResizer*/}
            {/*    heightCalculationMethod="bodyScroll"*/}

            {/*    src="https://nadataserver.dev.dycon.nl/widgetParent.html"*/}
            {/*    style={{ width: '1px', minWidth: '100%', height:'5000px', border: 'none'}}*/}
            {/*/>*/}




            <div
                dangerouslySetInnerHTML={{
                    __html: documentation
                }}></div>



        </CardContent>
    </Card>
    </>
);


