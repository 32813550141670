import React from "react";
import {
    EditGuesser
} from "@api-platform/admin";
import {
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    TextField,
    TextInput,
    ArrayInput,
    DateTimeInput,
    NumberInput,
    SimpleFormIterator,
    RadioButtonGroupInput,
    FormDataConsumer } from "react-admin";
import { VpnKey } from '@material-ui/icons';
import ApproveButton from './widgetsRegenerateKeyButton';
import {
    validateACLhostname,
    validateACLip,
    validateACT,
    validateFromDate,
    validateOwner,
    validateRequestInterval,
    validateRequestLimit,
    validatetoDate
} from "./WidgetsValidators";


const hasRole = (role) => {
    let permissions =  localStorage.getItem('permissions');
    let result = permissions.includes(role);
    return result;
}

const ConditionalACLip = () => (
    <ArrayInput source="acl" label="Access Control List">
        <SimpleFormIterator>
            <TextInput
                label="IP or CIDR"
                placeholder="255.255.255.255/32"
                validate={validateACLip}
            />
        </SimpleFormIterator>
    </ArrayInput>
);
const ConditionalACLhostname = () => (
    <ArrayInput source="acl" label="Access Control List">
        <SimpleFormIterator>
            <TextInput
                label="Hostname"
                placeholder="*.netanders.io"
                validate={validateACLhostname}
            />
        </SimpleFormIterator>
    </ArrayInput>
);

const WidgetEdit = props => (
    <EditGuesser {...props}>
        <TextField source="hash" />
        <ApproveButton>
            <VpnKey />
        </ApproveButton>
        <RadioButtonGroupInput source="act" label="Access control type" validate={validateACT} choices={[
            { id: 'none', name: 'No Access control (Default)' },
            { id: 'hostname', name: 'Hostname based access control (Recommended)' },
            { id: 'ip', name: 'IP based access control' },

        ]} />
        <FormDataConsumer>
            {({ formData, ...rest }) => formData.act === "ip" &&
                <ConditionalACLip {...rest} />
            }
        </FormDataConsumer>
            <FormDataConsumer>
            {({ formData, ...rest }) => formData.act === "hostname" &&
                <ConditionalACLhostname {...rest} />
            }
        </FormDataConsumer>
        {hasRole('NETANDERS_ADMIN') ? (
            <DateTimeInput source="validfrom" validate={validateFromDate}   />
        ) : null }
        {hasRole('NETANDERS_ADMIN') ? (
            <DateTimeInput source="validto"  validate={validatetoDate}  />
        ) : null }
        {hasRole('NETANDERS_ADMIN') ? (
            <NumberInput source="requestlimit" validate={validateRequestLimit} />
        ) : null }
        {hasRole('NETANDERS_ADMIN') ? (
            <SelectInput source="requestinterval" label="Limit clear interval" validate={validateRequestInterval}  choices={[
                { id: '10 minutes', name: '10 Minutes' },
                { id: '1 hours', name: '1 Hour' },
                { id: '1 days', name: '1 Day' },
                { id: '1 weeks', name: '1 Week' },
                { id: '1 months', name: '1 Month' },
            ]} />
        ) : null }
        {hasRole('NETANDERS_ADMIN') ? (
            <ReferenceInput validate={validateOwner}
                source="owner"
                reference="users"
                label="user"
                filterToQuery={searchText => ({ title: searchText })}match
            >
                <AutocompleteInput optionText="username" />
            </ReferenceInput>
        ) : null }
    </EditGuesser>
);

export default WidgetEdit;