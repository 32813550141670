// in src/Dashboard.js
import * as React from "react";
import { Card, CardContent, CardHeader } from '@material-ui/core';
import Title from "../Layout/title";




export default () => (

<>
    <Title pageTitle = "API Examples" />

    <Card>
        <CardHeader title="NetAnders API examples" />
        <CardContent>

            {/*<IframeResizer*/}
            {/*    heightCalculationMethod="bodyScroll"*/}

            {/*    src="https://nadataserver.dev.dycon.nl/widgetParent.html"*/}
            {/*    style={{ width: '1px', minWidth: '100%', height:'5000px', border: 'none'}}*/}
            {/*/>*/}






        </CardContent>
    </Card>
    </>
);


