import React from "react";
import { Filter, Pagination, DateField,  required , NumberField, ReferenceField, TextField,
    ChipField, DateInput, ReferenceInput, SelectInput } from 'react-admin';
import UtcDateField from '../Components/UtcDateField';
import {

    ListGuesser,

} from "@api-platform/admin";

import ListActions from './Utilization/CSVexport/actions';
import Title from "../Layout/title";

import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles({
    actions: {
        backgroundColor: '#fff',
    },
});

// var calculate = ({
//
//     /**
//      * Get corresponding energy unit from value
//      *
//      * @param {type} value      set value in Watts
//      * @returns {Number|String} returns value in corresponding unit size
//      */
//     formatkwh: function(value, volume) {
//         //Round to MW(h)
//         value = Math.round(value / 1000) * 1000;
//         //Prevent undefined return 0 W/Wh instead
//         if (typeof value === 'undefined') {
//             if (volume) {
//                 return 0 + " Wh";
//             } else {
//                 return 0 + "W"
//             }
//         }
//         value = value * 1000;
//         var mod = 1000;
//         var unitsText = '';
//         if (volume) {
//             unitsText = 'Wh kWh MWh GWh TWh PWh';
//         } else {
//             unitsText = 'W kW MW GW TW PW';
//         }
//         var units = unitsText.split(" ");
//         for (var i = 0; value > mod; i++) {
//             value = value / mod;
//         }
//
//
//         //Round to 2 decimals
//         value = Math.round(value * 100) / 100;
//         //Convert number to sting with thousend seperators and . or , in the users/browser preference
//         value = value.toLocaleString();
//         //Atach unit to value
//         value = value + ' ' + units[i];
//         //Return value
//         return  value;
//     }
// });

// const formatIriAsInt = v => {
//
//     if(v === parseInt(v)) {
//         return v;
//     }
//     if (v.indexOf('/') >= 0) {
//         v = v.split('/');
//
//         return parseInt(v[v.length - 1]);
//     }
//
//     return v;
// }

// const choices = [
//     { id: 'M', name: 'myroot.gender.male' },
//     { id: 'F', name: 'myroot.gender.female' },
// ];









const validateType = [required('Required')];

const today = new Date()
const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)

const todaystring = today.getFullYear() + "-" + ("0"+(today.getMonth()+1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2);
const tomorrowstring = tomorrow.getFullYear() + "-" + ("0"+(tomorrow.getMonth()+1)).slice(-2) + "-" + ("0" + tomorrow.getDate()).slice(-2);



// in src/utilizations.js
const UtilizationFilter = (props) => (
    <div>
    <Filter {...props}>



        <ReferenceInput alwaysOn  label="Point" source="point" reference="points"
                        allowEmpty={false}
                        validate={validateType}
                        allowNull
                        perPage={100}

        >
            <SelectInput  optionText="name"  />
        </ReferenceInput>



        <ReferenceInput alwaysOn  label="Type" source="type" reference="types"
                        allowEmpty={false}
                        validate={validateType}
                        allowNull
                        perPage={100}


        >
            <SelectInput  optionText="name" />
        </ReferenceInput>

        <ReferenceInput alwaysOn  label="Granularity" source="granularity" reference="granularities"
                        allowEmpty={false}
                        validate={validateType}
                        allowNull
                        format={(v) => (!v ? null : v)}
        >
            <SelectInput  optionText="name"  />
        </ReferenceInput>
        <ReferenceInput alwaysOn  label="GranularityTimeZone" source="granularitytimezone" reference="granularity_time_zones"
                        allowEmpty={false}
                        validate={validateType}
                        allowNull
                        format={(v) => (!v ? null : v)}
        >
            <SelectInput  optionText="name"  />
        </ReferenceInput>

        <ReferenceInput alwaysOn label="Classification" source="classification" reference="classifications"
                        allowEmpty={false}
                        validate={validateType}
                        allowNull
                        format={(v) => (!v ? null : v)}
        >
            <SelectInput  optionText="name" />
        </ReferenceInput>

        <ReferenceInput alwaysOn label="Activity" source="activity" reference="activities"
                        allowEmpty={false}
                        validate={validateType}
                        allowNull

                        format={(v) => (!v ? null : v)}>
            <SelectInput  optionText="name"   />
        </ReferenceInput>
        <DateInput source="validfrom[after]" alwaysOn  />
        <DateInput source="validfrom[strictly_before]" alwaysOn  />

    </Filter>

    </div>

);

//optionValue="originId"

const UtilizationPagination = props => <Pagination rowsPerPageOptions={[10, 12, 25, 31, 50, 100, 144, 200]} {...props} />


// const exporter = (utilizations, fetchRelatedRecords) => {
//     console.log(utilizations);
//     console.log(fetchRelatedRecords);
//     fetchRelatedRecords(utilizations, 'type').then(types => {
//             const data = types.map(utilization => ({
//                 ...utilization,
//                 type_name: 'naam'
//             }));
//
//         console.log(data);
//         const csv = convertToCSV({
//             data,
//             fields: ['id', 'type', 'type_name', 'volume'],
//         });
//         downloadCSV(csv, 'comments');
//     });
// };



const UtilizationList = (props) =>
{
    const classes = useStyles(props);

    return (
    <>
    <Title pageTitle="Utilization Export" />
    <ListGuesser  {...props} classes={{ content: classes.actions }} bulkActionButtons={false} filters={<UtilizationFilter />} hasShow={false} hasEdit={false} exporter={false} actions={<ListActions />}  pagination={<UtilizationPagination />} filterDefaultValues={
        { point: '/v1/points/0', type:'/v1/types/1', granularity:'/v1/granularities/3', granularitytimezone:'/v1/granularity_time_zones/1', classification:'/v1/classifications/2', activity:'/v1/activities/1',
            validfrom : {
            after: todaystring,
                strictly_before : tomorrowstring
            }}} sort={{ field: 'validfrom', order: 'ASC' }}>

        <ReferenceField label="Point" source={"point"} reference="points" link="show">
            <ChipField source="name" />
        </ReferenceField>
        <ReferenceField label="Type" source={"type"} reference="types" link="show" >
            <ChipField source="name"  />
        </ReferenceField>
        <ReferenceField label="Gran. Timezone" source={"granularitytimezone"} reference="granularity_time_zones" link="show">
            <ChipField source="name" />
        </ReferenceField>
        <ReferenceField label="Gran." source={"granularity"} reference="granularities" link="show">
            <ChipField source="name" />
        </ReferenceField>


        {/*<ReferenceField label="Activity" source={"activity"} reference="activities" link="show">*/}
        {/*    <ChipField source="name" />*/}
        {/*</ReferenceField>*/}
        {/*<ReferenceField label="Classification" source={"classification"} reference="classifications" link="show">*/}
        {/*    <ChipField source="name" />*/}
        {/*</ReferenceField>*/}

        <TextField source={"capacity"} label="Capacity (kW)" textAlign="right"  />
        <TextField source={"volume"} label="Volume (kWh)" textAlign="right"  />
        <NumberField source={"percentage"} options={{ style: 'percent' }} />
        <UtcDateField label="Validfrom (UTC)" source={"validfrom"} showTime />
        <UtcDateField label="Validto (UTC)"  source={"validto"} showTime  />
        <DateField source={"lastupdate"} />


    </ListGuesser >
        </>
);
}

export default UtilizationList;