import {
    required,
    minValue,
    number,
    choices
} from 'react-admin';

import isIp from "is-ip";
import isCidr from "is-cidr";
import validHostname from "is-valid-hostname";

const validateIpOrCidr = (value) => {
    return (isIp(value) || isCidr(value) > 0);
};
const validateHostname = (value) => {

    if(value) {
        // Dont allow hostnames to end with a wildcard.
        if (value.length > 1 && value.endsWith("*")) {
            return false;
        }

        // Allow only one wildcard
        var count = (value.match(/\*/g) || []).length;
        if(count > 1) {
            return false;
        }

        // Replace the wildcard with a placeholder to validate.
        let testvalue = value.replace(/\*/g, 'subdomain');
        console.log(testvalue);
        return (validHostname(testvalue));
    }
};

const validateRequestLimit = [required(), number(), minValue(1)];
export {validateRequestLimit}
const validateRequestInterval = [required(), choices(['10 minutes', '1 hours', '1 days', '1 weeks', '1 months'], 'Invalid option')];
export {validateRequestInterval}
const validateOwner = [required()];
export {validateOwner}
const validateACT = [choices(['none', 'hostname', 'ip'], 'Invalid option')];
export {validateACT}


const validateACLip = (value, allValues) => {
    //Validate if IP is there
    if (allValues.act !== 'ip') {
        return 'Invalid list for IP addresses';
    }
    // If multi value
    if (value instanceof Array) {
        for (var i = 0; i < value.length; i++) {
            // Make abriviation
            var cv = value[i];
            // If the value has been set.
            if(cv) {
                //Check if the value is a valid ip or cidr
                if (!validateIpOrCidr(cv)) {
                    return 'Not a valid IP or CIDR in row ' + (i + 1);
                }
            }
            // If the value has not been set yet return error.
            if(!cv) {
                return 'Not a valid IP or CIDR in row ' + (i + 1);
            }

        }
    } else {
        // If singualar value
        if (!validateIpOrCidr(value)) {
            return 'Not a valid IP or CIDR';
        }
    }
};
export {validateACLip}
const validateACLhostname = (value, allValues) => {
    //Validate if IP is there
    if (allValues.act !== 'hostname') {
        return 'Invalid list for hostnames';
    }
    // If multi value
    if (value instanceof Array) {
        for (var i = 0; i < value.length; i++) {
            // Make abriviation
            var cv = value[i];
            // If the value has been set.
            if(cv) {
                //Check if the value is a valid ip or cidr
                if (!validateHostname(cv)) {
                    return 'Not a valid hostname in row ' + (i + 1);
                }
            }
            // If the value has not been set yet return error.
            if(!cv) {
                return 'Not a valid hostname in row ' + (i + 1);
            }

        }
    } else {
        // If singualar value
        if (!validateHostname(value)) {
            return 'Not a valid hostname';
        }
    }
};
export {validateACLhostname}

const isDate = (s) => {
    if(isNaN(s) && !isNaN(Date.parse(s)))
        return true;
    else return false;
}
const validateFromDate = (value, allValues) => {
    if(value) {
        value = new Date(value);
        console.log(value);
        if (isDate(value)) {
            return 'Not a valid date';
        }
        let todate = new Date(allValues.validto);
        if(todate) {
            if(isDate(todate)){
                console.log("its a date");
            }

            let totime = todate.getTime()
            let valuetime = value.getTime()
            if(totime < valuetime) {
                return 'This date must be before the Validto date.';
            }
        }
    }
    if(!value) {
        if(value === 0) {
            return 'Not a valid date';
        }
    }
};
export {validateFromDate}
const validatetoDate = (value, allValues) => {
    if(value) {
        value = new Date(value);
        if (isDate(value)) {
            return 'Not a valid date';
        }
        let fromdate = new Date(allValues.validfrom);
        if(fromdate) {
            if(isDate(fromdate)){
                console.log("its a date");
            }

            let fromtime = fromdate.getTime()
            let valuetime = value.getTime()
            if(fromtime > valuetime) {
                return 'This date must be after the Validfrom date.';
            }
        }
    }
    if(!value) {
        if(value === 0) {
            return 'Not a valid date';
        }
    }
};
export {validatetoDate}
