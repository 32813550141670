// in src/Dashboard.js
import * as React from "react";
import Title from '../Layout/title';
import { Card, CardContent } from '@material-ui/core';
import IframeResizer from 'iframe-resizer-react';






export default () => (

<>
    <Title pageTitle="Widget Demo" />


    <Card>
        <CardContent>

            <IframeResizer
                heightCalculationMethod="bodyScroll"

                src="/Widgets/widgetDemo.html"
                style={{ width: '1px', minWidth: '100%', height:'5000px', border: 'none'}}
            />






        </CardContent>
    </Card>
    </>
);


