// admin/src/App.js

import React from "react";
import { HydraAdmin, ResourceGuesser, ListGuesser } from "@api-platform/admin";
import { Resource } from "react-admin";
import authProvider from './providers/authProviders';
import customRoutes from './routes';
import {createMuiTheme} from '@material-ui/core/styles';
import UtilizationList from './Entity/utilizations';
import WidgetList from './Entity/Widgets/widgetsList';
import WidgetEdit from './Entity/Widgets/widgetsEdit';
import WidgetsCreate from './Entity/Widgets/widgetsCreate';
import Dashboard from './Pages/dashboard';
import LoginPage from './Pages/login';
import layout from './Layout/layout';
import Menu from './Layout/Menu';
import dataProvider from './providers/dataProvider';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_ID
}
TagManager.initialize(tagManagerArgs)



const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
//02e15d38da58350758bed97514f9e695





const myTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#395951',
            contrastText: '#fff',
        },
        secondary: {
            main: '#fff',
        },
    },
});


const hasRole = (role) => {
    let permissions =  localStorage.getItem('permissions');
    if(permissions) {
        return permissions.includes(role);
    }
    return false;
};



function App() {
    window.dataLayer.push({
        event: 'pageview'
    });



    return (
        <>

        <HydraAdmin
            dashboard={ Dashboard }
            customRoutes={customRoutes}
            dataProvider={ dataProvider }
            authProvider={ authProvider }
            entrypoint={ entrypoint }
            loginPage={ LoginPage }
            layout ={ layout }
            menu = { Menu }
            theme ={ myTheme }

        >

            <ResourceGuesser name="activities" list={ListGuesser} />
            <ResourceGuesser name="authorisations" list={ListGuesser} />
            <ResourceGuesser name="classifications" list={ListGuesser} />
            <ResourceGuesser name="granularities" list={ListGuesser} />
            <ResourceGuesser name="granularity_time_zones" list={ListGuesser} />

            <ResourceGuesser name="users" list={ListGuesser} />
            <ResourceGuesser name="points" list={ListGuesser} />
            <ResourceGuesser name="types" list={ListGuesser} />
            <ResourceGuesser name="utilizations" list={ UtilizationList } />
            <Resource name="widgets" list={ WidgetList } edit={WidgetEdit} create={ hasRole('NETANDERS_ADMIN') ? WidgetsCreate : null }  />

        </HydraAdmin>
            </>
    );
}


export default App;