const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

export default {
    login: ({ username }) => {

        const request = new Request(entrypoint + "/users/tokenlogin", {
            method: "POST",
            body:  JSON.stringify({ "x-auth-token": username }),
            headers: new Headers({ "Content-Type": "application/json", "x-auth-token": username  })
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                let newresponse =   response.json();
                return newresponse;
            })
            .then(data => {
                localStorage.setItem('token', data.apikey);
                localStorage.setItem('permissions', data.roles);
                localStorage.setItem('username', data.username);
                window.location.reload();
            })         ;

    },
    logout: () => {
        sessionDestroy();
        return Promise.resolve();
    },
    checkError: ({ status }) => {
        if (status === 401) {
            sessionDestroy();
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject();
    },
    getPermissions: () => Promise.resolve(),


};

function sessionDestroy() {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    localStorage.removeItem('username');
}