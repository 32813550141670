// in src/Dashboard.js
import React from "react";
import { Card, CardContent, CardHeader, Typography, List, ListItem } from '@material-ui/core';
import Title from "../Layout/title";

export default function Dashboard() {
    return (
        <>
            <Title pageTitle="CSV Documentation" />
            <Card>
                <CardHeader title="Ned.nl CSV Documentation" />
                <CardContent>
                    <Typography variant="h5" component="h2">
                        CSV Export Function
                    </Typography>

                    <Typography paragraph>
                        The CSV export feature allows you to transform Ned.nl API data into
                        a file format consisting of comma-separated values (CSV), making it easy
                        to save and share information.
                    </Typography>

                    <Typography variant="h5" component="h2">
                        Filtering and Sorting
                    </Typography>

                    <Typography paragraph>
                        It is important to note that any constraints applied through filtering
                        and sorting options will be reflected in the exported CSV data.
                    </Typography>

                    <Typography variant="h5" component="h2">
                        File Format Details
                    </Typography>

                    <Typography paragraph>
                        Structure: The data in the CSV file is structured with commas separating
                        each column, and quotation marks (") encapsulating any fields where
                        necessary. Rows are separated by newline characters.
                    </Typography>

                    <Typography paragraph>
                        Headers: The file starts with a header row to denote column names.
                    </Typography>

                    <Typography paragraph>
                        Encoding: The CSV file is encoded in Unicode (UTF-8) to ensure
                        compatibility with diverse systems and platforms.
                    </Typography>

                    <Typography variant="h5" component="h2">
                        Column References
                    </Typography>

                    <Typography paragraph>
                        For clarity and ease of use, the CSV file uses column labels instead of ID references found in the API. Fields such as @type, @id, granularitytimezone, ID, activity, and classification are excluded from the CSV output.
                    </Typography>

                    <Typography variant="h5" component="h2">
                        Export Limitations
                    </Typography>

                    <List>
                        <ListItem>
                            Row Limit: The maximum number of rows per export is limited to 65,000 to accommodate older versions of Excel.
                        </ListItem>
                        <ListItem>
                            File Availability: Exported files will be accessible for download for a period of two days from the start time of generation.
                        </ListItem>
                        <ListItem>
                            Filtering: Only a single filter may be applied per column within the exported data.
                        </ListItem>
                    </List>

                    <Typography variant="body1">
                        <strong>Note:</strong>
                    </Typography>

                    <Typography paragraph>
                        Please wait for your current export process to complete before initiating another. Patience ensures that the system functions smoothly for all users.
                        Avoid initiating a re-export if the process appears slow, as it may lead to longer wait times due to increased load on the export queue.
                    </Typography>

                </CardContent>
            </Card>
        </>
    );
}