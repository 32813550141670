import * as React from 'react';
import { Login } from 'react-admin';
import LoginForm from './LoginForm';

const LoginPage = (props) => (
    <Login {...props} backgroundImage='/ned/images/bg.svg'>
      <LoginForm />
    </Login>
  );
  
export default LoginPage;