import React from 'react';
import { Route } from 'react-router-dom';
//import LessonEditPage from './LessonEditPage';
//

import WidgetDemo from './Pages/widgetDemo';
import WidgetWizzard from './Pages/widgetWizzard';
import WidgetDocumentation from './Pages/widgetDocumentation';
import CsvDocumentation from './Pages/csvDocumentation';
import HelpPage from './Pages/help';
import ApiExamplesPage from './Pages/apiExamples';

export default [
//    <Route exact path="/course-plan/:plan_id/plan-lesson/:id" component={LessonEditPage} />

    <Route exact path="/widget_demo" component={WidgetDemo} />,
    <Route exact path="/widget_wizzard" component={WidgetWizzard} />,
    <Route exact path="/help" component={HelpPage} />,
    <Route exact path="/widget_documentation" component={WidgetDocumentation} />,
    <Route exact path="/api_examples" component={ApiExamplesPage} />,
    <Route exact path="/csv_documentation" component={CsvDocumentation} />

    ];