import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {AccessAlarm, GetApp, List, HelpOutline, Code, ViewList, Description   } from '@material-ui/icons';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { withRouter } from 'react-router-dom';
import {
    translate,
    MenuItemLink,
    Responsive,
} from 'react-admin';

// import visitors from '../visitors';
// import orders from '../orders';
// import invoices from '../invoices';
// import products from '../products';
// import categories from '../categories';
// import reviews from '../reviews';
import SubMenu from './SubMenu';



class Menu extends Component {
    state = {
        menuCatalog: false,
        menuSales: false,
        menuCustomers: false,
    };

    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object,
    };



    handleToggle = menu => {
        this.setState(state => ({ [menu]: !state[menu] }));
    };

    render() {
        const { onMenuClick, open, logout } = this.props;

        const goToAPIDocumentation = (e) => {
            e.preventDefault();
            e.stopPropagation();
            window.open("https://api.ned.nl/v1", '_blank');

        };

        return (
            <div>

                <MenuItemLink
                    to={`/`}
                    primaryText={"API Viewer"}
                    leftIcon={<DashboardIcon />}
                    onClick={onMenuClick}
                />

                <SubMenu
                    handleToggle={() => this.handleToggle('menuSales')}
                    isOpen={this.state.menuSales}
                    sidebarIsOpen={open}
                    name="Ned.nl API"
                    icon={<ViewList />}
                >



                    <MenuItemLink
                        to={`/activities`}
                        primaryText={"Activities"}
                        leftIcon={<List />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to={`/authorisations`}
                        primaryText={"Authorisations"}
                        leftIcon={<List />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to={`/classifications`}
                        primaryText={"Classifications"}
                        leftIcon={<List />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to={`/granularities`}
                        primaryText={"Granularities"}
                        leftIcon={<List />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to={`/granularity_time_zones`}
                        primaryText={"Granularity time zones"}
                        leftIcon={<List />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to={`/users`}
                        primaryText={"Users"}
                        leftIcon={<List />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to={`/points`}
                        primaryText={"Points"}
                        leftIcon={<List />}
                        onClick={onMenuClick}
                    />

                    <MenuItemLink
                        to={`/types`}
                        primaryText={"Types"}
                        leftIcon={<List />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to={`/utilizations`}
                        primaryText={"Utilizations"}
                        leftIcon={<List />}
                        onClick={onMenuClick}
                    />

                    <MenuItemLink
                        to={`https://api.ned.nl/v1`}
                        target={"_blank"}
                        primaryText={"API Documentation"}
                        leftIcon={<Description />}
                        onClick={goToAPIDocumentation}
                    />
                    <MenuItemLink
                        to={`/api_examples`}
                        primaryText={"Examples"}
                        leftIcon={<Code />}
                        onClick={onMenuClick}
                    />
                </SubMenu>
                <SubMenu
                    handleToggle={() => this.handleToggle('menuCustomer')}
                    isOpen={this.state.menuCustomer}
                    sidebarIsOpen={open}
                    name="Downloads"
                    icon={<GetApp />}
                >
                    <MenuItemLink
                        to={`/utilizations`}
                        primaryText="Download CSV/XLS"
                        leftIcon={<List />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to={`/csv_documentation`}
                        primaryText={"CSV Documentation"}
                        leftIcon={<Description />}
                        onClick={onMenuClick}
                    />

                </SubMenu>
                <MenuItemLink
                    to={`/help`}
                    primaryText="Help"
                    leftIcon={<HelpOutline />}
                    onClick={onMenuClick}
                />
                <Responsive
                    xsmall={
                        <MenuItemLink
                            to="/configuration"
                            primaryText='pos.configuration'
                            leftIcon={<AccessAlarm />}
                            onClick={onMenuClick}
                        />
                    }
                    medium={null}
                />
                <Responsive
                    small={logout}
                    medium={null} // Pass null to render nothing on larger devices
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    // theme: state.theme,
    // locale: state.i18n.locale,
});

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {}
    ),
    translate
);

export default enhance(Menu);