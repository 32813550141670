import React from 'react';


export default function UtcDateField(props) {


    function pad(n){return n<10 ? '0'+n : n}


    const utcdate = props.record[props.source];

    const date = new Date(utcdate);


    //const utcFormattedDate = pad(date.getUTCDay()) +'-'+ pad((date.getUTCMonth()+1)) +'-'+ pad(date.getUTCFullYear());//prints expected format.

    const utcFormattedDate = pad(date.getUTCFullYear()) +'-'+ pad((date.getUTCMonth()+1)) +'-'+ pad(date.getUTCDate());//prints expected format.
    const utcFormattedTime = ' '+ pad(date.getUTCHours()) +':'+ pad(date.getUTCMinutes()) +':'+ pad(date.getUTCSeconds())
    const utcFormatted = utcFormattedDate + utcFormattedTime;

    return <span>{ utcFormatted }</span>
}