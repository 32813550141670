// in src/Dashboard.js
import * as React from "react";
import { Card, CardContent, CardHeader } from '@material-ui/core';
import Title from "../Layout/title";



export default () => (


<>
    <Title pageTitle = "API Viewer" />
    <Card>
        <CardHeader title="Welcome to the Ned.nl API Viewer" />
        <CardContent>
            {/*<Typography paragraph>*/}
            {/*    Welcome to the Ned.nl API Dashboard, your premier source for detailed renewable energy data.*/}
            {/*    Our Utilization endpoint is at the forefront of our services, offering you powerful access*/}
            {/*    to energy usage data which can be readily exported to CSV format for your convenience.*/}
            {/*</Typography>*/}
            {/*<Typography >*/}
            {/*    Harness the full potential of our comprehensive Utilization data to drive your analytics,*/}
            {/*    research, or operational management. Whether you're looking to optimize energy consumption,*/}
            {/*    perform detailed market analysis, or integrate renewable energy statistics into your business*/}
            {/*    model, our API delivers the data you need in an accessible and user-friendly manner.*/}
            {/*</Typography>*/}
            {/*<Typography>*/}
            {/*    Leverage our endpoint to seamlessly extract precise Utilization records and transform them*/}
            {/*    into actionable insights with just a few clicks. Start empowering your decision-making process*/}
            {/*    today with the precision and reliability of Ned.nl's renewable energy data.*/}
            {/*</Typography>*/}
        </CardContent>
    </Card>
    </>
);


